// TODO: FIX scrolling for Safari
// Nav & Isometric Image Scroll effects
// Set initial window Y pixel position
var scroll_pos = $(window).scrollTop();
var device_h = window.innerHeight;

$(window).scroll(function(){

  // Nav background enabled at 550 Y pixels
  $('nav').toggleClass('scrolled', $(this).scrollTop() > device_h * .85);

  // Tool-kit text fade effect
  if ($(this).scrollTop() > device_h * 1.1) {
    $('.isoheader').fadeIn(3000);
  }
  if ($(this).scrollTop() > device_h * 1.2) {
    $('.isotext').fadeIn(3000);
  }

  // TODO: Left off here for scroll logic adjustments based on section height changing
  // Isometric scrolling enabled between 700-900 Y pixels
  if (device_h * 1.5 < $(this).scrollTop() < device_h * 1.6) {
    var iso_scroll_pos = $('.isometric_section').scrollTop();
    // Scrolling down
    if (scroll_pos < $(this).scrollTop() && $('.isometric_section').scrollTop() < 850) {
      $('.isometric_section').scrollTop(iso_scroll_pos+2)
    // Scrolling up
    }else if($('.isometric_section').scrollTop() > 300){
      $('.isometric_section').scrollTop(iso_scroll_pos-2)
    }
  }

  // Log window Y pixel location to determine next scroll direction
  scroll_pos = $(window).scrollTop();
});
